import Aj from './Ajax_get'
import Ajax_get from './Ajax_get';
class Sheet24class{
  // eslint-disable-next-line no-useless-constructor
  constructor() {
     this.Aj =  new Ajax_get();
     this.data24={};
     this.data24.data=[];
     this.data24.gyomu=[];
     this.data24.gyomu.data=[];
     this.gyoum24 =[];
     this.onehour=[];
  }
 async get_data(){
     var data = await this.Aj.get_data2('/api/care/gyomu24',null);
     this.data24.data  = data.data;
     this.data24.gyomu = data.gyomu;
     this.data_seikei();
     return this.data24;
  }
  //データを1時間ごとに整形
  data_seikei(){
    var v1=[]
    for (let key in this.data24.gyomu) {
      var v={} 
       v.ID = this.data24.gyomu[key];
       v1.push (v); 
    
    }
    for (let key in this.data24.gyomu) {
      
       var d1 = new Date('2020/04/01 00:00:00');
       var d2 = new Date('2020/04/01 01:00:00');
       var res=[]
       var gi = this.data24.gyomu[key].G_ID
     for(var i=0 ;i<24; i++){
      //console.log(d1);
        
        var dv=0;
        var gid=-1;
        var gname="";
        var  oner = {}
         for (var v2 of this.data24.data) {
               var da = new Date(v2.SDATE);
                 if (da >= d1 && da<d2 && v2.G_ID == gi ){
                   dv=dv+1;
               
              } 
         }
        
         oner['kaisu'] = dv;
         oner['g'] = gi;
         oner['gname'] =  this.data24.gyomu[key].G_NAME;
  
         
         res.push(oner);

        d1.setHours(d1.getHours() + 1);  
        d2.setHours(d2.getHours() + 1);  
       
      }
      this.gyoum24.push(res);
    }    
    
  }
  //スタッフマスタ取得
  staff(id){ 
    var s = this.data.staff[id];
    return s.NAME;
 }
}
export default Sheet24class;
