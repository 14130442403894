import React,{Component}  from 'react';
import {Link} from 'react-router-dom';
import Carecon from '../class/Careclass';  

function Table1(props) {
  if(props.data.length >0 ){
    //ID, staff_ID, DATE, shift_ID, SDATE, EDATE, NOTE
    var row  = props.data.map((d,index)=>{
      return(
       <tr>
         <td>{d.DATE}</td>
         <td>{d.staff_ID}</td>
         <td>{d.SDATE}</td>
         <td>{d.EDATE}</td>
       </tr>
       );   
    }
   );
  return(
      <div>
      {row}
      </div>
    
  );  
  }
  return(
   <div>boke</div>
  );
}

class Care extends Component {
// eslint-disable-next-line no-useless-constructor
 constructor() {
        super();
        this.Carecon = new Carecon();
        this.state={
            Carecon : this.Carecon,        
        }
    }
async componentDidMount(){
  var res =  await this.Carecon.get_data();
  this.carecon_up();
}
carecon_up(){
  this.setState(
    {Carecon:this.Carecon}
  ) 
}    
    
render() {
  return(
    <div className="container-fluid">
       <div className="alert alert-primary" role="alert">
      勤務表  
      </div>
      <Table1  
          data={this.state.Carecon.data.data}        
      />
        <div className="alert alert-secondary" role="alert">
        A simple secondary alert—check it out!
        </div>
        <div className="alert alert-success" role="alert">
        A simple success alert—check it out!
        </div>
            </div>
  )    
  }  
}
export default Care;