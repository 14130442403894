import React,{Component}  from 'react';
import Sheet24class from '../class/Sheet24class';


class Sh24 extends Component {
// eslint-disable-next-line no-useless-constructor
 constructor() {
        super();
        this.Sh24c = new Sheet24class();
        this.state={
          Sh24c : this.Sh24c,
          Rowdata:[]        
        }
    }
async componentDidMount(){
      var res =  await this.Sh24c.get_data();
      this.carecon_up();
      var d = this.Sh24c.gyoum24
      this.setState({
          Rowdata:d
      })
    }
carecon_up(){
      this.setState(
        {Sh24c : this.Sh24c}
      ) 
    }       
render() {
  var rowdata = this.state.Rowdata
  var row=[]
  rowdata.forEach( function( item ) {
       row.push(
       <tr>
         <td>{item[0].gname}</td>
         <td>{item[0].kaisu}</td>
         <td>{item[1].kaisu}</td>
         <td>{item[2].kaisu}</td>
         <td>{item[3].kaisu}</td>
         <td>{item[4].kaisu}</td>
         <td>{item[5].kaisu}</td>
         <td>{item[6].kaisu}</td>
         <td>{item[7].kaisu}</td>
         <td>{item[8].kaisu}</td>
         <td>{item[9].kaisu}</td>
         <td>{item[10].kaisu}</td>
         <td>{item[11].kaisu}</td>
         <td>{item[12].kaisu}</td>
         <td>{item[13].kaisu}</td>
         <td>{item[14].kaisu}</td>
         <td>{item[15].kaisu}</td>
         <td>{item[16].kaisu}</td>
         <td>{item[17].kaisu}</td>
         <td>{item[18].kaisu}</td>
         <td>{item[19].kaisu}</td>
         <td>{item[20].kaisu}</td>
         <td>{item[21].kaisu}</td>
         <td>{item[22].kaisu}</td>
         <td>{item[23].kaisu}</td>
        </tr>
       );
  });
   
  return(
    <div className="container-fluid">
       <div className="alert alert-primary" role="alert">
         24時間シート
       </div>
       <div>
         <table>
          <tbody> 
           <tr>
             <th>業務</th>
             <th>0時</th> <th> 1時</th><th>2時</th><th>3時</th> <th> 4時</th><th>5時</th>
             <th>6時</th><th>7時</th> <th> 8時</th><th>9時</th><th>10時</th><th>11時</th>
             <th>12時</th><th>13時</th><th>14時</th><th>15時</th><th>16時</th><th>17時</th>
             <th>18時</th><th>19時</th><th>20時</th> <th> 21時</th><th>22時</th><th>23時</th>
           </tr>
           {row}
           </tbody>
           
         </table>
       </div> 
    </div>
  )    
  }  
}
export default Sh24;