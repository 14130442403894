import Aj from './Ajax_get'
import Ajax_get from './Ajax_get';
class Careclass{
  // eslint-disable-next-line no-useless-constructor
  constructor() {
     this.Aj =  new Ajax_get();
     this.data={};
     this.data.data=[];
  }
 async get_data(){
    this.data= await this.Aj.get_data2('/api/care/data_all',null);
    
    return this.data;
  }
  //データ整形
  data_seikei(){
    var res=[];
    
    this.data.data.forEach(d => {
      var dres;
      dres = d;
      dres.test="bake";
      res.push(dres);
    });
   this.data.data = res; 
  }
  //スタッフマスタ取得
  staff(id){ 
    var s = this.data.staff[id];
    return s.NAME;
 }
}
export default Careclass;
