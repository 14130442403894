import axios from 'axios';
class Ajax_get{
  constructor() {
  this.base_url = '';
  var host = window.location.hostname ;
  
  if(host==='iot2.nogyo.org'){
    this.base_url ='https://iot.nogyo.org/sensor_server/api'
   }
  if(host==='192.168.11.62'){
    this.base_url ='http://192.168.11.62/slara/public'
   }
  
  }
async get_data(url) {
    const questions = (await axios.get(this.base_url+url,{
      headers: 
      {
        Accept: "application/json",
        'Content-Type':'application/json',
      },data:{}

  })).data;
    return questions;
}
async get_data_full(url) {
  const questions = (await axios.get(url,{
    headers: {
      Accept: "application/json",
      'Content-Type':'application/json',
    },data:{}

})).data;
  return questions;
}
async get_data2(url,data2) {
  const questions = (await axios.get(this.base_url+url,{
    params:data2
})).data;
  return questions;
}
 get_all_data2(url,callback) {
  //gdata/test_data
   axios
 .get(this.base_url+url,{
    headers: {
      Accept: "application/json",
      'Content-Type':'application/json',
    }
  })
 .then(response => {
   callback(response)
 })
 .catch(e => {
   console.log("Error occurred in API")
   console.log(e)
 });
  }
  async post_data(url,data) {
    const questions = (await axios.post(this.base_url+url,{
      headers: {
        Accept: "application/json",
        'Content-Type':'application/json',
      },data:data
  })).data;
  return questions;
 }

async post_data2(url,data) {
 const questions = (await axios.post(this.base_url+url,
  data,
  {
   headers: {
     Accept: "application/json",
     'Content-Type':'application/x-www-form-urlencoded',
     },
    })).data;
  return questions;
}
//ファイル送信 
async post_flle(url,data){
const questions = (await axios.post(this.base_url+url,
  data,
  {
   headers: {
     Accept: "application/json",
     'Content-Type':'multipart/form-data',
     },
    })).data;
  return questions;
}
//crosテスト
async cros_data(url) {
const questions = (await axios.get(this.base_url+url,{
  headers: {
    Accept: "application/json",
    'Content-Type':'application/json',
    'Access-Control-Allow-Origin':"*",
    'Access-Control-Allow-Methods': "POST,GET,PUT,DELETE"

  },data:{}
})).data;
return questions;
}   
}

export default Ajax_get;
