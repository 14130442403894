import React,{Component}  from 'react';
import {Link} from 'react-router-dom';

import ajpy from '../class/Ajax_getpy';
  

class Menu extends Component {
// eslint-disable-next-line no-useless-constructor
 constructor() {
        super();
        this.ajpy = new ajpy();
    }
  async componentDidMount(){
      var res =  await this.ajpy.get_data('');
      
      this.setState({
          Res:res
      })
    }   
render() {
  return(
    <div className="container-fluid">
     
       <Link to="/care">
         <div className="alert alert-primary" role="alert">
         ケア
        </div>
        </Link>
        <Link to="/sh24">
          <div className="alert alert-secondary" role="alert">
            24時間シート飯塚
          </div>
        </Link> 
        <Link to="/chart">
        <div className="alert alert-success" role="alert">
            チャート
        </div>
       </Link> 
            </div>
  )    
  }  
}
export default Menu;