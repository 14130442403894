import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './container/Menu'
import Care from './container/Care'
import Sh24 from './container/Sh24'
import Chart from './container/Chart'
function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route exact path='/' component={Menu}/>
      <Route exact path='/care' component={Care}/>
      <Route exact path='/sh24' component={Sh24}/>
      <Route exact path='/chart' component={Chart}/>
    </Switch>
   </Router> 
    
  );
}

export default App;
