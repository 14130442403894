import React,{Component}  from 'react';
import Plot from 'react-plotly.js';
import Ajax_getpy from '../class/Ajax_getpy';
import {Nav,NavDropdown} from 'react-bootstrap';
import Moment from 'react-moment';
function CounterList(props) {
  const counters = props.days.map(counter => {
    return (
      <NavDropdown.Item eventKey={counter.val}>{counter.title}</NavDropdown.Item>
    );
  });
  return (
    <NavDropdown title={props.drop} id="nav-dropdown">
    {counters}
    </NavDropdown>
  );
}

class Chart extends Component {
// eslint-disable-next-line no-useless-constructor
 constructor() {
  super(); 
    this.Aj = new Ajax_getpy();
      this.state={
        data:{},
        days:[],
        days2:[]
      }
    this.handleSelect = this.handleSelect.bind(this);
    this.days =this.days.bind(this);
    }
async componentDidMount(){
   var d = await this.Aj.get_data('/kinm_oneday_gantt/0_2020-04-01_00:00:00');
   this.days();
   this.days2();
   this.setState({
     data:d      
  }) 
}
async handleSelect(eventKey){
  var d={}
  d = await this.Aj.get_data(eventKey);
  /*
  if(eventKey ==1){
     d = await this.Aj.get_data('');
  }
  if(eventKey =="p1"){
    d = await this.Aj.get_data('/gantt_sleep');
  }
  if(eventKey =="p2"){
    d = await this.Aj.get_data('/gantt_gyom_shift/2_2020-04-01 00:00:00');
  }
  if(eventKey =="p3"){
    d = await this.Aj.get_data('/kinm_oneday_gantt/0_2020-04-01 00:00:00');
  }
  */
  this.setState({
    data:d      
 });
  
}
group(){
   var res=[
     {ID:1,NAME:"うめ"},
     {ID:2,NAME:"すいせん"},
     {ID:3,NAME:"あやめ"},
     {ID:4,NAME:"あじさい"},
     {ID:5,NAME:"ひまわり"},
     {ID:6,NAME:"ショート"},
     {ID:7,NAME:"専門"},
     {ID:8,NAME:"看護"},
     {ID:9,NAME:"アルバイト"}
   ];
  return res; 
}
formatDate (date, format) {
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
  return format;
};
days(){
  var days=[]
  var res =this.group();
  var d={}
  var ld = new Date(2020, 6, 1);
  var d1 = new Date(2020, 3, 1);
  res.forEach( item => {
   for(var i=0;i<60;i++) {
       d={}
       d['title'] = item.NAME + this.formatDate(d1,"yyyy-MM-dd" );
       d['val'] = "/gantt_gyom_shift/2_"+ this.formatDate(d1,"yyyy-MM-dd_00:00:00" ) 
       d1.setDate(d1.getDate() + 1);
       days.push(d)
     
    }
   d1 =  new Date(2020, 3, 1);
});   
  this.setState(
    {
      days:days,
      res:res,
    })  
}
days2(){
  var days2=[]
  var d={}
  var ld = new Date(2020, 6, 1);
  var d1 = new Date(2020, 3, 1);
  for(var i=0;i<60;i++) {
       d={}
       d['title'] = this.formatDate(d1,"yyyy-MM-dd" );
       d['val'] = "/kinm_oneday_gantt/0_"+ this.formatDate(d1,"yyyy-MM-dd_00:00:00" ) 
       d1.setDate(d1.getDate() + 1);
       days2.push(d)
       
  }
   d1 =  new Date(2020, 3, 1);

  this.setState(
    {
      days2:days2,
    })  
}
carecon_up(){
    }       
render() {
     return(
      <div> 
      <Nav variant="pills" activeKey="1" onSelect={this.handleSelect}>
  
      <NavDropdown title="ガントチャート" id="nav-dropdown">
        <NavDropdown.Item eventKey="/gantt_sleep">ガントSLEEP</NavDropdown.Item>
       
      </NavDropdown>
      
      <CounterList
                days={this.state.days} 
                drop="ガント業務勤務ユニット別"           
              />
      <CounterList
                days={this.state.days2} 
                drop="ガント勤務日別"           
              />     
      
     </Nav>
      
      <Plot  style={{ width: '100%' }}
      data={this.state.data.data}
      layout={ this.state.data.layout }
    />
    </div>
  )    
  }  
}
export default Chart;